import Button from '../button/Button';
import {useTranslation} from 'next-i18next';
import useEmblaCarousel from 'embla-carousel-react';
import {DotButton} from '../embla/EmblaDots';
import Autoplay from 'embla-carousel-autoplay';
import {useCallback, useEffect, useState} from 'react';
import Image from 'next/image';
import img from '@/public/img/homepageImg.jpg';
import img2 from '@/public/img/rooms/2.jpg';
import img3 from '@/public/img/rooms/5.jpg';
import img4 from '@/public/img/rooms/HHExcslusive10.jpg';
import img5 from '@/public/img/rooms/HHExcslusive05.jpg';
import {NextButton, PrevButton} from '../embla/EmblaArrow';

const HomepageHero = () => {
    const {t} = useTranslation('home');

    const [emblaRef, emblaApi] = useEmblaCarousel({loop: true}, [Autoplay({delay: 3000})]);
    const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
    const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

    const scrollPrev = useCallback(() => emblaApi && emblaApi.scrollPrev(), [emblaApi]);
    const scrollNext = useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi]);
    const scrollTo = useCallback((index: number) => emblaApi && emblaApi.scrollTo(index), [emblaApi]);

    const onSelect = useCallback(() => {
        if (!emblaApi) return;
        setSelectedIndex(emblaApi.selectedScrollSnap());
        setPrevBtnEnabled(emblaApi.canScrollPrev());
        setNextBtnEnabled(emblaApi.canScrollNext());
    }, [emblaApi, setSelectedIndex]);

    useEffect(() => {
        if (!emblaApi) return;
        onSelect();
        setScrollSnaps(emblaApi.scrollSnapList());
        emblaApi.on('select', onSelect);
        emblaApi.on('reInit', onSelect);
    }, [emblaApi, setScrollSnaps, onSelect]);
    return (
        <section className='bg-[#131313] py-20 mb-[120px] relative'>
            <div className='container mx-auto px-4'>
                <div className='flex items-start flex-col'>
                    <h1 className='text-white text-4xl xl:text-[64px] max-w-[503px] xl:leading-[70px] mb-8 font-bold'>{t('hero.title')}</h1>
                    <p className='text-white max-w-[544px] mb-4 font-light'>
                        {t('hero.description')}
                    </p>
                    <p className='text-white max-w-[504px] mb-8 font-light whitespace-pre-wrap'>
                        {t('hero.hashtags')}
                    </p>
                    <Button text={t('hero.btn')} link='#rezervace' style='yellow'/>
                </div>
                <div
                    className='embla-homepage embla relative xl:absolute xl:bottom-[-64px] xl:-right-0 xl:w-[720px] h-80 xl:h-[464px] p-6 xl:pr-0 border border-yellow bg-[#131313] mt-10'>
                    <div className='embla__viewport h-full' ref={emblaRef}>
                        <div className='embla__container h-full'>
                            <div className='embla__slide'>
                                <div className='homepageHeroOverlay'></div>
                                <Image src={img} alt='' fill style={{objectFit: 'cover'}} priority></Image>
                            </div>
                            <div className='embla__slide'>
                                <div className='homepageHeroOverlay'></div>
                                <Image src={img2} alt='' fill style={{objectFit: 'cover'}}></Image>
                            </div>
                            <div className='embla__slide'>
                                <div className='homepageHeroOverlay'></div>
                                <Image src={img3} alt='' fill style={{objectFit: 'cover'}}></Image>
                            </div>
                            <div className='embla__slide'>
                                <div className='homepageHeroOverlay'></div>
                                <Image src={img4} alt='' fill style={{objectFit: 'cover'}}></Image>
                            </div>
                            <div className='embla__slide'>
                                <div className='homepageHeroOverlay'></div>
                                <Image src={img5} alt='' fill style={{objectFit: 'cover'}}></Image>
                            </div>
                        </div>
                    </div>
                    <div
                        className='embla__dots z-1 bottom-6 md:bottom-14 left-1/2 -translate-x-1/2 md:left-20 xl:right-[600px]'>
                        {scrollSnaps.map((_, index) => (
                            <DotButton key={index} selected={index === selectedIndex} onClick={() => scrollTo(index)}/>
                        ))}
                    </div>
                    <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled}/>
                    <NextButton onClick={scrollNext} enabled={nextBtnEnabled}/>
                </div>
            </div>
        </section>
    );
};

export default HomepageHero;
