import SectionTitle from '../text/SectionTitle';
import { useTranslation } from 'next-i18next';
import FaqItem from './FaqItem';
const Faq = () => {
  const { t } = useTranslation('common');
  return (
    <section className='mb-28'>
      <div className='container mx-auto px-4 flex flex-col'>
        <div className={`container mx-auto flex items-center faqWrapper`}>
          <div className={'flex-auto flex flex-col'}>
            <div className='flex-auto mb-2 w-full'>
              <FaqItem question={t('faq.questions.0')} answer={t('faq.answers.0')}></FaqItem>
              <FaqItem question={t('faq.questions.1')} answer={t('faq.answers.1')}></FaqItem>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
