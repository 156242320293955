import SectionTitle from '../text/SectionTitle';
import { useTranslation } from 'next-i18next';
import { useEffect, useRef, useState } from 'react';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/themes/dark.css';
import { Czech } from 'flatpickr/dist/l10n/cs.js';
import { useRouter } from 'next/router';
import { useReCaptcha } from 'next-recaptcha-v3';
const ReservationSection = () => {
  const { t } = useTranslation(['common']);
  let arrivalCalendar = useRef(null);
  let departureCalendar = useRef(null);
  const nextRouter = useRouter();
  let { locale: activeLocale } = nextRouter;
  const { executeRecaptcha } = useReCaptcha();

  const handleReservation = async (e: any) => {
    e.preventDefault();
    const axios = (await import('axios')).default;
    // @ts-ignore
    if (!arrivalCalendar.current.selectedDates[0] || !departureCalendar.current.selectedDates[0]) {
      alert(t('reservation.datesError'));
    }
    const token = await executeRecaptcha('form_submit');
    const data = {
      name: e.target.name.value,
      phone: e.target.phone.value,
      wish: e.target.wish?.value,
      // @ts-ignore
      dateOfArrival: arrivalCalendar.current.selectedDates[0],
      // @ts-ignore
      dateOfDeparture: departureCalendar.current.selectedDates[0],
      timeOfArrival: e.target.arrivalTime?.value,
      timeOfDeparture: e.target.departureTime?.value,
      recaptchaToken: token,
    };

    axios.post('/api/createReservation', { data }).then((res) => {
      if (res.status === 200) {
        (document.getElementById('reservationForm') as HTMLFormElement).reset();
        // @ts-ignore
        document.getElementById('reservationInfoText').innerText = t('reservation.reservationConfirm');
        setTimeout(() => {
          // @ts-ignore
          document.getElementById('reservationInfoText').innerText = t('reservation.info');
        }, 5000);
      }
    });
  };

  useEffect(() => {
    if (departureCalendar.current && arrivalCalendar.current) {
      if (activeLocale === 'cs') {
        // @ts-ignore
        departureCalendar.current.set('locale', Czech);
        // @ts-ignore
        arrivalCalendar.current.set('locale', Czech);
      } else {
        // @ts-ignore
        departureCalendar.current.set('locale', null);
        // @ts-ignore
        arrivalCalendar.current.set('locale', null);
      }
    }
  }, [activeLocale]);

  // @ts-ignore
  Date.prototype.addDays = function (days: any) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };
  useEffect(() => {
    // @ts-ignore
    departureCalendar.current = flatpickr('.flatpickrEnd', {
      inline: true,
      defaultDate: new Date(),
      minDate: new Date(),
      dateFormat: 'd.m.Y',
      locale: activeLocale === 'cs' ? Czech : null,
    });
    // @ts-ignore
    arrivalCalendar.current = flatpickr('.flatpickrStart', {
      inline: true,
      defaultDate: new Date(),
      minDate: new Date(),
      dateFormat: 'd.m.Y',
      locale: activeLocale === 'cs' ? Czech : null,
      onChange: (selectedDates) => {
        if (
          // @ts-ignore
          departureCalendar.current.selectedDates[0] &&
          // @ts-ignore
          new Date(departureCalendar.current.selectedDates[0].toDateString()) < new Date(selectedDates[0].toDateString())
        ) {
          // @ts-ignore
          departureCalendar.current.setDate(new Date(selectedDates[0]).toLocaleString('pl'));
          // @ts-ignore
          departureCalendar.current.set('minDate', new Date(selectedDates[0]).toLocaleString('pl'));
          // @ts-ignore
        } else if (departureCalendar.current.selectedDates[0]) {
          // @ts-ignore
          departureCalendar.current.set('minDate', new Date(selectedDates[0]).toLocaleString('pl'));
          // @ts-ignore
          departureCalendar.current.setDate(new Date(selectedDates[0]).toLocaleString('pl'));
        }
      },
    });
  }, []);
  return (
    <section className='mb-28' id='rezervace'>
      <div className='container mx-auto px-4 flex-col flex'>
        <SectionTitle text={t('reservation.title')}></SectionTitle>
        <form action='' className='flex  flex-col' id='reservationForm' onSubmit={handleReservation}>
          <div className='flex mb-4 flex-wrap xl:flex-nowrap'>
            <div className='flex flex-col w-full mb-10 xl:mb-0 xl:pr-8'>
              <label className='mb-2 font-semibold text-sm' htmlFor='name'>
                {t('reservation.name')}
              </label>
              <input
                className='bg-yellow outline-0 px-6 py-4 mb-6 placeholder:text-black text-sm'
                type='text'
                name='name'
                id='name'
                autoComplete='name'
                placeholder={t('reservation.namePlaceholder') || 'Name'}
              />
              <label className='mb-2 font-semibold text-sm' htmlFor='phone'>
                {t('reservation.phone')}
              </label>
              <input
                className='bg-yellow outline-0 px-6 py-4 mb-6 placeholder:text-black text-sm'
                type='text'
                pattern='\+{0,1}[0-9]{1,13}'
                name='phone'
                maxLength={13}
                id='phone'
                required
                placeholder={t('reservation.phonePlaceholder') || 'Phone'}
              />
              <label className='mb-2 font-semibold text-sm' htmlFor='wish'>
                {t('reservation.wish')}
              </label>
              <textarea
                className='bg-yellow outline-0 px-6 py-4 resize-none placeholder:text-black h-[184px] text-sm'
                name='wish'
                id='wish'
                maxLength={200}
                placeholder={t('reservation.wishPlaceholder') || 'Your wish'}
              />
            </div>
            <div className='flex flex-col w-full mx-auto md:w-1/2 md:pr-4 xl:w-full'>
              <p className='mb-2 font-semibold'>{t('reservation.dateOfArrival')}</p>
              <div className='flatpickrStart'></div>

              <label className='mb-2 font-semibold text-sm' htmlFor='name'>
                {t('reservation.timeOfArrival')}
              </label>
              <div className='arrivalWrapper mb-6'>
                <input
                  className='bg-yellow outline-0 px-6 py-4 placeholder:text-black text-sm w-full pl-12'
                  type='time'
                  name='arrivalTime'
                  id='arrivalTime'
                  placeholder={t('reservation.timeOfArrivalPlaceholder') || 'Name'}
                />
              </div>
            </div>
            <div className='flex flex-col w-full mx-auto md:w-1/2 md:pl-4 xl:w-full'>
              <p className='mb-2 font-semibold'>{t('reservation.dateOfDeparture')}</p>
              <div className='flatpickrEnd'></div>
              <label className='mb-2 font-semibold text-sm' htmlFor='name'>
                {t('reservation.timeOfDeparture')}
              </label>
              <div className='departureWrapper mb-6'>
                <input
                  className='bg-yellow outline-0 px-6 py-4 placeholder:text-black text-sm w-full pl-12'
                  type='time'
                  name='departureTime'
                  id='departureTime'
                  placeholder={t('reservation.timeOfDeparturePlaceholder') || 'Name'}
                />
              </div>
            </div>
          </div>
          <div className='flex flex-col xl:flex-row items-start xl:items-center justify-between'>
            <span className='xl:mr-8 font-semibold mb-4 xl:mb-0' id='reservationInfoText'>
              {t('reservation.info')}
            </span>
            <button className={`w-max text-white px-[24px] py-[16px] bg-red font-semibold`} type='submit'>
              {t('reservation.btn')}
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default ReservationSection;
