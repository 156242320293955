type Props = {
  text: string;
  color?: string;
};

const SectionTitle = ({ text, color = 'black' }: Props) => {
  return (
    <h2 className={`text-${color} text-2xl xl:text-[32px] flex items-center mb-10 font-semibold`}>
      <svg className='mr-4' width='56' height='2' viewBox='0 0 56 2' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <line x1='8.74228e-08' y1='1' x2='56' y2='1' stroke='#F0B10D' strokeWidth='2' />
      </svg>
      {text}
    </h2>
  );
};

export default SectionTitle;
