import Link from 'next/link';
import { scrollToAnchor } from 'utils/scrollToAnchor';

type Props = {
  text: string;
  link: string;
  style: string;
};

const Button = ({ text, link, style }: Props) => {
  if (link === '#rezervace') {
    return (
      <Link
        onClick={(e) => scrollToAnchor(e, '#rezervace')}
        className={`text-white px-[24px] py-[16px] bg-${style} font-semibold hover:bg-${style}-hover transition-colors`}
        href={link}>
        {text}
      </Link>
    );
  } else {
    return (
      <Link className={`text-white px-[24px] py-[16px] bg-${style} font-semibold hover:bg-${style}-hover transition-colors`} href={link}>
        {text}
      </Link>
    );
  }
};

export default Button;
