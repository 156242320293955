import { useState } from 'react';

type Props = {
  question: string;
  answer: string;
};

const FaqItem = ({ question, answer }: Props) => {
  // const [expanded, setExpanded] = useState(false);
  return (
    <div className='flex flex-col py-3 border-b text-gray-light'>
      <div className='flex flex-row items-center'>
        <p className={`flex-auto font-medium text-2xl mb-2`}>{question}</p>
        {/* <svg
          width='48'
          height='48'
          viewBox='0 0 48 48'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          className={`${expanded ? 'rotate-180' : ''} transition-transform`}>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M24 7C14.626 7 7 14.626 7 24C7 33.374 14.626 41 24 41C33.374 41 41 33.374 41 24C41 14.626 33.374 7 24 7ZM24 44C12.972 44 4 35.028 4 24C4 12.972 12.972 4 24 4C35.028 4 44 12.972 44 24C44 35.028 35.028 44 24 44Z'
            fill='#F0B10D'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M24 29.5878C23.602 29.5878 23.218 29.4298 22.938 29.1458L15.996 22.1738C15.41 21.5858 15.414 20.6358 16 20.0518C16.588 19.4678 17.538 19.4678 18.122 20.0558L24 25.9638L29.88 20.0558C30.464 19.4678 31.414 19.4678 32.002 20.0518C32.588 20.6358 32.59 21.5858 32.006 22.1738L25.062 29.1458C24.782 29.4298 24.398 29.5878 24 29.5878Z'
            fill='#F0B10D'
          />
        </svg> */}
      </div>
      <div className={`transition-max-height duration-700 ease-in-out overflow-hidden max-h-20`}>
        <p>{answer}</p>
      </div>
    </div>
  );
};

export default FaqItem;
