import Head from 'next/head';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Hero from 'components/hero/HomepageHero';
import USPSection from 'components/usps/USPSection';
import ReservationSection from 'components/reservation/ReservationSection';
import Faq from 'components/faq/Faq';
import { NextSeo } from 'next-seo';
import { useTranslation } from 'next-i18next';
export default function Home() {
  const { t } = useTranslation('home');
  return (
    <>
      <NextSeo
        title={t('meta.title') || ''}
        description={t('meta.desc') || ''}
        openGraph={{ title: t('meta.title') || '', description: t('meta.desc') || '' }}
      />
      <Hero />
      <USPSection />
      <ReservationSection></ReservationSection>
      <Faq></Faq>
    </>
  );
}

export async function getStaticProps({ locale }: any) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common', 'home'])),
    },
  };
}
